import React, { useState, useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import $ from 'jquery'

import Layout from '../../components/Layout'

import undraw_development_ouy3 from '../../resources/images/vectors/undraw_development_ouy3.svg'

import locked_n_loaded from '../../resources/images/aws_thank_you_page/step_02.png'
import license_key from '../../resources/images/aws_thank_you_page/step_03.png'
import slack_community from '../../resources/images/aws_thank_you_page/step_04.png'

import aws_marketplace from '../../resources/images/aws_thank_you_page/step_05.png'
import configure_deployment from '../../resources/images/aws_thank_you_page/step_06.png'
import create_stack from '../../resources/images/aws_thank_you_page/step_07.png'
import configure_stack_details from '../../resources/images/aws_thank_you_page/step_08.png'
import configure_stack_options from '../../resources/images/aws_thank_you_page/step_09.png'
import review_create_stack from '../../resources/images/aws_thank_you_page/step_10.png'
import fqdn from '../../resources/images/aws_thank_you_page/step_11.png'
import login from '../../resources/images/aws_thank_you_page/step_12.png'

import undraw_message_sent_1030 from '../../resources/images/vectors/undraw_message_sent_1030.svg'
import { initializeVideos } from '../../helpers/initializeVideo'

interface StateProps {
  heroSectionState: HeroState
}
interface HeroState {
  heroSection: object
}

const gettingStartedOnAwsMsk: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query FormTrialSubmittedAWSSEO {
      allContentfulPages(
        filter: {
          pageReference: {
            pageIdentifier: { eq: "Cloud_Getting_Started_AWS_MSK" }
          }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const [heroSectionState, setHeroSectionState] = useState<HeroState>({
    heroSection: null,
  })
  const { heroSection } = heroSectionState

  useEffect(() => {
    //  Smooth Scrolling
    $('.btn').on('click', function (event) {
      if (this.hash !== '') {
        event.preventDefault()
        const hash = this.hash
        $('html,body').animate(
          {
            scrollTop: $(hash).offset().top,
          },
          650
        )
      }
    })
    initializeVideos()
    var url_string = window.location.href
    var url = new URL(url_string)
    var source = url.searchParams.get('s')

    if (source) {
      if (source === 'trial') {
        let tempHero = (
          <section className="py-5 mt-5 pb-4">
            <div className="container-1">
              <div className="d-flex flex-wrap mt-3 py-5">
                <div className="col-md-8 offset-md-2 text-center pt-0">
                  <img
                    className="img-fluid w-50 mx-auto d-block"
                    src={undraw_message_sent_1030}
                    alt="enterprise ok"
                  />
                  <h1 className="mt-3">you&apos;re all set!</h1>
                  <p className="hero-description-dark">
                    Just follow the instructions below to get setup!
                  </p>
                </div>
                <div className="col-md-8 col-12 offset-md-2 pt-0">
                  <p className="text-center">
                    <small>
                      Didn&apos;t get the email? Contact{' '}
                      <a
                        className="link-text"
                        href="mailto:info@lenses.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@lenses.io
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </section>
        )

        setHeroSectionState({
          heroSection: tempHero,
        })
      }
    }
  }, [])

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      {heroSection && heroSection}
      <section className={`${heroSection ? '' : 'mt-5'} pt-5 pb-5 bg-gray`}>
        <div className="container-1 pb-5">
          <div className="d-flex flex-wrap mt-3 pb-5 align-items-center">
            <div className="col-md-5 col-12 pt-5">
              <h1 className="pt-2">
                Get started with
                <br /> Lenses.io for AWS MSK
              </h1>
              <div className="pr-3">
                <p className="hero-description-dark pr-5 f-22 lh-35">
                  Full visibility and control over your real-time data and
                  applications on AWS Managed Streaming for Apache Kafka.
                  <br />
                  <br />
                  Get started now!
                </p>
              </div>
              <div>
                <div className="roll-button">
                  <a
                    href="#step1"
                    className="bg-red text-white text-uppercase mt-1 mr-2"
                  >
                    <span data-hover="Get Started">Get Started</span>
                    <i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                  </a>
                  <a
                    href="/downloads/lenses-enterprise/?s=other"
                    className="text-white blue-bg text-uppercase mt-1"
                  >
                    <span data-hover="Free license key">Free license key</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-7 pt-5">
              <div className="cloud-aws-msk-video">
                <iframe
                  src=""
                  data-src="//player.vimeo.com/video/429702449"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 mb-5 mobile-padding-reset mobile-no-overflow"
        id="step1"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-8 pt-2 col-12">
              <p className="box-title-number  mobile-margin-top">01.</p>
              <h1 className="pt-2 ">
                What do I get with Lenses.io <br /> for MSK?
              </h1>
              <div className="pr-3">
                <p className="hero-description-dark pr-5  mobile-padding-reset mobile-margin-top">
                  In just a few short minutes, Lenses.io will be deployed into
                  your own AWS VPC environment and connected to your AWS MSK
                  cluster. Finally giving you complete visibility and control
                  over your real-time data and applications including:
                </p>
                <ul className="pl-3">
                  <li>Data access with SQL</li>
                  <li>Self-service data & application operations</li>
                  <li>
                    Real-time app and data integration development with SQL
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 pt-5 col-12">
              <div className="pt-5"></div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step2">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light py-5 mobile-padding-reset mobile-no-overflow"
        id="step2"
      >
        <div className="container-1 py-5 mobile-padding-reset ">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-0 col-12">
              <p className="box-title-number  mobile-margin-top">02.</p>
              <h1 className="pt-2 ">Pre-requisites</h1>
              <div className="pr-3 mobile-padding-left">
                <p className="hero-description-dark pr-5 mb-0 pb-0 mobile-padding-reset mobile-margin-top">
                  Ensure your{' '}
                  <a
                    href="//aws.amazon.com/msk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Amazon MSK{' '}
                  </a>{' '}
                  environment is up and running. Have the cluster details handy
                  (such as the ARN handy), you’ll need it later.
                </p>
                <p>
                  You’ll need an AWS account with permissions to deploy
                  Cloudformation templates via the AWS marketplace.
                </p>
                <p>
                  For full details see our
                  <a href="https://docs.lenses.io/4.3/installation/cloud/aws/">
                    {' '}
                    documentation
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="mt-2 w-100 d-none d-md-block"
                src={locked_n_loaded}
                alt="locked and loaded"
              />
            </div>

            <div className="col-md-12 text-center col-12 p-0">
              <a className="btn" href="#step3">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step3"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-6  pt-2 col-12">
              <p className="box-title-number  mobile-margin-top">03.</p>
              <h1 className="pt-2 ">Get your license key</h1>
              <div className="pr-3 mobile-padding-reset">
                <p className="hero-description-dark pr-5 mobile-padding-reset  mobile-margin-top">
                  Download your Lenses.io license key. You will have received an
                  email with a link. If not, be sure to sign up
                  <a href="https://lenses.io/downloads/lenses-enterprise/?s=other">
                    {' '}
                    here
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <img
                  className="img-fluid mx-auto d-block"
                  src={license_key}
                  alt="license key"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step4">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light py-5 mobile-padding-reset mobile-no-overflow"
        id="step4"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">04.</p>
              <h1 className="pt-2 ">Connect to community Slack</h1>
              <div className="pr-3 mobile-padding-reset">
                <p className="hero-description-dark pr-5 mobile-padding-reset pb-3  mobile-margin-top">
                  In case you hit any bumps in the road, connect with the
                  Lenses.io engineering team and thousands of other Apache Kafka
                  community members via{' '}
                  <a href="https://launchpass.com/lensesio">
                    https://launchpass.com/lensesio
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-3">
                <img
                  className="img-fluid mx-auto d-block shadow"
                  src={slack_community}
                  alt="slack community"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step5">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step5"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-5 col-12">
              <p className="box-title-number mobile-margin-top ">05.</p>
              <h1 className="pt-2 ">Access the AWS Marketplace</h1>
              <div className="pr-3 mobile-padding-reset">
                <p className="hero-description-dark pr-5  mobile-padding-reset mobile-margin-top">
                  Subscribe to Lenses.io in the{' '}
                  <a
                    className="link-text"
                    href="//aws.amazon.com/marketplace/pp/Lensesio-Lenses-EC2/B07Q88VPTW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    AWS Marketplace{' '}
                  </a>{' '}
                  <br />
                  and subscribe to the product.
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <img
                  className="img-fluid mx-auto d-block shadow"
                  src={aws_marketplace}
                  alt="AWS marketplace"
                />
              </div>
            </div>

            <div className="col-md-12 text-center col-12">
              <a className="btn" href="#step6">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 bg-light mobile-padding-reset mobile-no-overflow"
        id="step6"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap mobile-padding-reset pb-0">
            <div className="col-md-6 pt-3 col-12">
              <p className="pt-5 box-title-number mobile-margin-top ">06.</p>
              <h1 className="pt-2 ">Configure your deployment</h1>

              <p className="hero-description-dark pr-5 mb-0 mobile-padding-reset  mobile-margin-top">
                Configure the Lenses.io instance that will be deployed via
                Cloudformation. Select the &ldquo;Lenses EC2 MSK&rdquo; instance
                and the latest version. Deploy in your preferred region and
                Launch CloudFormation!
              </p>
            </div>

            <div className="col-md-6 col-12 justify-content-center align-self-center">
              <div className="mobile-margin-top ">
                <img
                  className="img-fluid mx-auto d-block shadow "
                  src={configure_deployment}
                  alt="configure-deployment"
                />
              </div>
            </div>

            <div className="col-md-6 offset-md-1 pt-3 col-12"></div>

            <div className="col-md-12 text-center col-12">
              <a className="btn" href="#step7">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step7"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">07.</p>
              <h1 className="pt-2 ">Create the stack</h1>
              <div className="pr-3 mobile-padding-reset">
                <p className="hero-description-dark pr-5 mobile-padding-reset ">
                  The template is ready with no need to customize. Do not change
                  the Amazon S3 URL value.
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <img
                  className="img-fluid mx-auto d-block shadow"
                  src={create_stack}
                  alt="create stack"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step8">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light py-5 mobile-padding-reset mobile-no-overflow"
        id="step8"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number mobile-margin-top ">08.</p>
              <h1 className="pt-2 ">Configure stack details</h1>
              <div className="pr-3 mobile-padding-reset">
                <div className="hero-description-dark pr-5 mobile-padding-reset ">
                  <ol>
                    <li>Choose a name for your Lenses.io deployment stack</li>
                    <li>
                      Configure the details of your VPC and Subnet to deploy to
                    </li>
                    <li>Select a t2.large instance.</li>
                    <li>
                      Set a CIDR range for SSHLocation and LensesLocation in
                      accordance with your security policies
                    </li>
                    <li>
                      Paste in the license key in the form of a JSON object
                      directly into the Lenses License field.
                    </li>
                    <li>
                      Copy the ARN and Security Group Applied from your MSK
                      Cluster Summary page.
                    </li>
                    <li>Select to send the metrics to Cloudwatch.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-5">
              <div className="">
                <img
                  className="img-fluid mx-auto d-block"
                  src={configure_stack_details}
                  alt="Configure stack details"
                />
              </div>
            </div>
            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step9">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section
          className=" p-5 mobile-padding-reset mobile-no-overflow"
          id="step9"
        >
          <div className="container-1 p-5 mobile-padding-reset">
            <div className="row p-5 mobile-padding-reset">
              <div className="col-md-6  offset-md-1 pt-3 col-12">
                <p className="box-title-number  mobile-margin-top">
                  09.
                </p>
                <h1 className="pt-2 ">
                  Configure stack details continued
                </h1>

                <div className="pr-3 mobile-padding-reset mobile-margin-top">
                  <p className="hero-description-dark pr-5 pb-0 mobile-padding-reset ">
                    <ol>
                      <li>
                        Paste in the license key in the form of a JSON object
                        directly into the Lenses License field.{' '}
                      </li>
                      <li>
                        Copy the ARN and Security Group Applied from your MSK
                        Cluster Summary page.{' '}
                      </li>
                      <li>Select to send the metrics to Cloudwatch. </li>
                    </ol>
                    <br />
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-md-5  col-12 mt-5">
                <div>
                  <img
                    className="img-fluid mx-auto d-block shadow-lg mt-5"
                    src={configure_stack_details_2}
                    alt="configure_stack_details 2"
                  />
                  <img
                    className="img-fluid mx-auto d-block shadow-lg mt-5"
                    src={configure_stack_details_3}
                    alt="configure_stack_details 3"
                  />
                  <img
                    className="img-fluid mx-auto d-block shadow-lg mt-5"
                    src={configure_stack_details_4}
                    alt="configure_stack_details 4"
                  />
                </div>
              </div>

              <div className="col-md-12 col-12 text-center">
                <a className="btn" href="#step10">
                  <i className="pt-4 fa fa-angle-down f-40" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </section> */}

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step9"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">09.</p>
              <h1 className="pt-2 ">Configure stack options</h1>

              <div className="pr-3 mobile-padding-reset mobile-margin-top">
                <p className="hero-description-dark pr-5 pb-0 mobile-padding-reset ">
                  Add tags of your choice to identify and classify your
                  Lenses.io stack.
                  <br />
                  <br />
                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 mt-5">
              <div>
                <img
                  className="img-fluid mx-auto d-block shadow-lg mt-5"
                  src={configure_stack_options}
                  alt="Configure stack options"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step10">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-light py-5 mobile-padding-reset mobile-no-overflow"
        id="step10"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">10.</p>
              <h1 className="pt-2 ">Review & create stack</h1>

              <div className="pr-3 mobile-padding-reset mobile-margin-top">
                <p className="hero-description-dark pr-5 pb-0 mobile-padding-reset ">
                  Review all the configuration & accept the acknowledgement
                  before creating your stack.
                  {/* <ol>
                      <li>
                        Paste in the license key in the form of a JSON object
                        directly into the Lenses License field.{' '}
                      </li>
                      <li>
                        Copy the ARN and Security Group Applied from your MSK
                        Cluster Summary page.{' '}
                      </li>
                      <li>Select to send the metrics to Cloudwatch. </li>
                    </ol> */}
                  <br />
                  <br />
                </p>
              </div>
            </div>

            <div className="col-md-6  col-12 mt-5">
              <div>
                <img
                  className="img-fluid mx-auto d-block shadow-lg mt-5"
                  src={review_create_stack}
                  alt="Review create stack"
                />
                {/* <img
                    className="img-fluid mx-auto d-block shadow-lg mt-5"
                    src={review_create_stack_2}
                    alt="Review create stack 2"
                  /> */}
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step11">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step11"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">11.</p>
              <h1 className="pt-2 ">Find FQDN of created stack</h1>

              <div className="pr-3 mobile-padding-reset mobile-margin-top">
                <p className="hero-description-dark pr-5 pb-0 mobile-padding-reset ">
                  From the Outputs of your stack - access Lenses.io from your
                  browser via the FQDN on port 9991
                  <br />
                  <br />
                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 mt-5">
              <div>
                <img
                  className="img-fluid mx-auto d-block shadow-lg mt-5"
                  src={fqdn}
                  alt="fqdn"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step12">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light py-5 mobile-padding-reset mobile-no-overflow"
        id="step12"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-5 col-12 pt-5 ">
              <p className="box-title-number  mobile-margin-top">12.</p>
              <h1 className="pt-2">Login and enjoy</h1>
              <div className="pr-3 mobile-padding-reset">
                <p className="hero-description-dark pr-5 mobile-padding-reset">
                  Login with username admin and the password as the instance ID
                  (Physical ID) of your Lenses EC2 instance
                </p>
              </div>
            </div>

            <div className="col-md-7 col-12 pt-5">
              <div className="pt-5">
                <img
                  className="img-fluid mx-auto d-block"
                  src={login}
                  alt="login portal"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a className="btn" href="#step13">
                <i
                  className="pt-4 fa fa-angle-down f-40"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-red pb-5" id="step13">
          <div className="container  pt-4">
            <div className="row pt-4 mt-5">
              <div className="col-md-12 col-12 text-center">
                <img
                  className="img-fluid w-25 mx-auto d-block text-center"
                  src={undraw_monitor_iqpq}
                  alt="connect your cluster"
                />
                <p className="mb-5 text-white paragraph-title">
                  Want to connect to your cluster?
                </p>
                <div className="mb-5 roll-button">
                  <a
                    className="bg-white primary-text text-uppercase"
                    href="/contact-us/"
                  >
                    <span data-hover="Talk to our Team">Talk to our Team</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <section
        className="py-5 mobile-padding-reset mobile-no-overflow"
        id="step13"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5">
            <div className="col-md-12 col-12 text-center pt-5">
              <img
                className="img-fluid w-25 mx-auto d-block text-center"
                src={undraw_development_ouy3}
                alt="frequently asked questions-faq"
              />
              <h1 className="pt-2">Frequently Asked Questions</h1>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-md-8 col-12 offset-md-2">
              {/* <!-- FAQ-1 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq1"
                        className="faq-link"
                      >
                        How I can enable JMX metrics with Lenses and MSK
                        brokers?
                      </a>
                    </div>
                  </div>
                  <div id="faq1" className="collapse">
                    <div className="card-body ">
                      <p>
                        You need to enable Open Monitoring during the deployment
                        of MSK or use the quick-create starter cluster with
                        recommended settings (where Open Monitoring is enabled
                        by default)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-2 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq2"
                        className="faq-link"
                      >
                        Where can I get full documentation of the installation
                        process?
                      </a>
                    </div>
                  </div>
                  <div id="faq2" className="collapse">
                    <div className="card-body">
                      <p>
                        See{' '}
                        <a href="https://docs.lenses.io/4.3/installation/cloud/aws/">
                          here
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-3 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq3"
                        className="faq-link"
                      >
                        How can I get help during my trial?
                      </a>
                    </div>
                  </div>
                  <div id="faq3" className="collapse">
                    <div className="card-body">
                      <p>
                        Speak to our engineering team on our community Slack
                        channel.
                      </p>
                      <p>
                        {' '}
                        Register{' '}
                        <a href="https://launchpass.com/lensesio">here</a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-5 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq5"
                        className="faq-link"
                      >
                        Does the MSK support JMX metrics for Zookeeper nodes?
                      </a>
                    </div>
                  </div>
                  <div id="faq5" className="collapse">
                    <div className="card-body">
                      <p>
                        MSK currently does not provide any means of collecting
                        Zookeeper JMX metrics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-6 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq6"
                        className="faq-link"
                      >
                        How can I ssh to the Lenses EC2 Instance?
                      </a>
                    </div>
                  </div>
                  <div id="faq6" className="collapse">
                    <div className="card-body">
                      <p>
                        You will need to use the AWS EC2 Instance Connect CLI
                        (the mssh command).
                      </p>
                      <p>
                        See{' '}
                        <a href="//docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-connect-methods.html">
                          here
                        </a>{' '}
                        for more details.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-7 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq7"
                        className="faq-link"
                      >
                        Where can I find the Lenses configuration is in the EC2?
                      </a>
                    </div>
                  </div>
                  <div id="faq7" className="collapse">
                    <div className="card-body">
                      <p>As a root under the /opt/lenses directory</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap mt-5">
            <div className="col-md-12 text-center col-12">
              Do you have more questions?
              <br />
              <a
                className="primary-text"
                href="/contact-us/"
                id="aws-thank-you-contactus"
              >
                Let us help <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default gettingStartedOnAwsMsk
